<!--
 * @Author: v_bbinvxu v_bbinvxu@tencent.com
 * @Date: 2023-05-29 10:21:07
 * @LastEditors: v_bbinvxu v_bbinvxu@tencent.com
 * @LastEditTime: 2023-05-29 11:11:35
 * @FilePath: \cqwh\src\views\home\home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <header :class="[hederStyle, 'header']">
      <div class="header-container">
        <h1 class="logo">
          <img class="logo-left" :src="logoSrcLeft" alt="财付通小贷logo" />
          <img class="logo-right" :src="logoSrcRight" alt="财付通小贷logo" />
        </h1>

        <!-- pc菜单 -->
        <nav id="navigator" v-if="!isUseMobileStyle">
          <ul>
            <li
              class="nav-li"
              v-for="(item, index) in navList"
              :key="item.title"
              @mouseover="hoverIndex = index"
              @mouseleave="hoverIndex = ''"
            >
              <div
                class="fp-tooltip animate-item-faster2"
                :class="`${
                  index === activePage - 1 || hoverIndex === index
                    ? 'fp-tooltip-active'
                    : 'fp-tooltip'
                }`"
              >
                <a @click="clickMenu1(index + 1, item)" :href="item.enTitle"> {{ item.title }} </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <nav v-if="isUseMobileStyle" class="menu">
        <div class="menu" @click="clickMenu">
          <img class="menu-icon" :src="moreMenu ? menuSrc : closeSrc" alt="菜单图片" />
        </div>
      </nav>
      <!-- 移动端弹出菜单 -->
      <div class="menuModal" v-if="!moreMenu && isUseMobileStyle">
        <ul>
          <li
            class="nav-li"
            v-for="(item, index) in navList"
            :key="item.title"
            @mouseover="hoverIndex = index"
            @mouseleave="hoverIndex = ''"
          >
            <div
              class="fp-tooltip"
              @click="menuClick(item.enTitle)"
              :class="`${
                index === activePage - 1 || hoverIndex === index
                  ? 'fp-tooltip-active'
                  : 'fp-tooltip'
              }`"
            >
              <a :href="item.enTitle" @click="moreMenu = true"> {{ item.title }} </a>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="main">
      <section class="section" id="home">
        <div class="main-video-container">
          <div class="main-video">
            <img v-if="!isUseMobileStyle" src="@/assets/background/banner.png" alt="首屏图" />
            <img v-else src="@/assets/background/banner-mob.png" alt="首屏图" />
          </div>
        </div>
      </section>

      <section class="section" id="education">
        <div class="main-video-container info-announce">
          <div class="title">
            <div class="title-top">
              <h2>信息公告</h2>
            </div>
            <div class="info-title-bottom">INFORMATION ANNOUNCEMENT</div>
          </div>
          <div class="education-list">
            <div class="education-list-right">
              <img :src="isUseMobileStyle ? InfoMob : InfoPc" alt="财付通小贷背景图2" srcset="" />
            </div>
            <div class="education-list-left">
              <div class="msg-list-wrap">
                <div
                  v-for="(item, index) in infoDataList.data"
                  :key="index"
                  class="msg-item"
                  @click="handle(item)"
                >
                  <div class="table-time">
                    <div class="table-day">{{ item.day }}</div>
                    <div>{{ item.time }}</div>
                  </div>
                  <div class="table-title-con">
                    <div class="table-title">{{ item.title }}</div>
                    <div class="table-dsc">{{ item.dsc }}</div>
                  </div>
                </div>
              </div>
              <el-pagination
                style="text-align: right"
                class="table-pagination"
                layout="total,prev, pager, next"
                :total="infoDataList.list.length"
                :page-size="3"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </section>
      <section class="section" id="aboutus">
        <div class="main-video-container info-announce about">
          <div class="title">
            <div class="title-top">
              <h2>关于我们</h2>
            </div>

            <div class="info-title-bottom">ABOUT US</div>
          </div>
          <div class="about-list">
            <div class="about-list-left">
              <img :src="isUseMobileStyle ? AboutMob : AboutPc" alt="关于我们图3" srcset="" />
            </div>
            <div class="about-list-right">
              <div class="about-list-right-con">
                <div class="about-time">
                  重庆市微恒科技有限公司成立于2022年06月02日，注册地位于重庆市北碚区云汉大道117号附938号
                </div>
                <div class="about-conect"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="footer">
      <div class="record-number" @click="toRecord">渝ICP备2022013684号</div>
    </footer>
    <div class="toTop" @click="toTop" v-if="hederStyle && isUseMobileStyle">
      <img src="@/assets/background/to-top.png" alt="返回顶部图片" />
    </div>
  </div>
</template>

<script>
import LogoRight from '@/assets/background/logo-right.png';
import LogoRightNew from '@/assets/background/logo-right-new.png';
import LogoLeft from '@/assets/background/logo-left.png';
import LogoLeftNew from '@/assets/background/logo-left-new.png';
import InfoPc from '@/assets/background/info-pc.png';
import InfoMob from '@/assets/background/info-mob.png';
import AboutPc from '@/assets/background/about-pc.png';
import AboutMob from '@/assets/background/about-mob.png';
import menuWhite from '@/assets/background/menu-white.png';
import menuBlack from '@/assets/background/menu-black.png';
import closeBlack from '@/assets/background/close-black.png';
import closeWhite from '@/assets/background/close-white.png';

import { isMobile, isAndroid } from '@/utils/common';
import { mtaReport, MTA_MENU_ID } from '@/utils/mta/mta';
import './styles/mobile.scss';
import './styles/pc.scss';

const policyTitle = '微恒科技隐私政策公告';
const policyPath = '/privacyPolicy';

export default {
  name: 'home',
  components: {},
  data() {
    return {
      activePage: 1,
      hoverIndex: '',
      view: {},
      navList: [
        {
          title: '首页',
          enTitle: '#home',
          event: 'weiheng_net_home.click',
          menuId: MTA_MENU_ID.home,
        },
        {
          title: '信息公告',
          enTitle: '#education',
          event: 'weiheng_net_info.click',
          menuId: MTA_MENU_ID.education,
        },
        {
          title: '关于我们',
          enTitle: '#aboutus',
          event: 'weiheng_net_aboutus.click',
          menuId: MTA_MENU_ID.about,
        },
      ],

      infoDataList: {
        list: [
          {
            title: policyTitle,
            path: policyPath,
            time: '2025-02',
            day: '24',
            dsc: `你好！因公司业务发展需要，我司注册地址于2025年1月15日起正式变更为重庆市北碚区云汉大道117号附938号，
            原注册地址将停止使用。我们对《微恒科技隐私政策》中的注册地址进行了修订，请你查看最新的《微恒科技隐私政策》，如有任何疑问，你可以向我们的客服（95017）进行咨询。`,
            nbr: 3,
            event: 'weihengi_net_info.click.notice3',
            menuId: MTA_MENU_ID.education,
          },

          {
            title: policyTitle,
            path: policyPath,
            time: '2023-05',
            day: '30',
            dsc: `你好！为了更好地保障金融消费者的合法权益，我们对 《微恒科技隐私政策》条款进行了部分修订，
            请你查看最新的《微恒科技隐私政策》，如有任何疑问，你可以向我们的客服进行咨询。`,
            nbr: 2,
            event: 'weihengi_net_info.click.notice2',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: policyTitle,
            path: policyPath,
            time: '2022-12',
            day: '12',
            dsc: `你好！为了更好地保障金融消费者的合法权益，我们制定了 《微恒科技隐私政策》，
            希望你仔细阅读以下相关内容，我们将严格依据国家法律法规处理你的个人信息，确保信息安全，
            保障你的隐私权益。如有任何疑问，你可以向我们的客服进行咨询。`,
            nbr: 1,
            event: 'weihengi_net_info.click.notice1',
            menuId: MTA_MENU_ID.education,
          },
        ],
        data: [],
      },
      currentPage: 1,
      sliceData: {
        size: 0,
        result: [],
      },
      hederStyle: '',
      logoSrcRight: LogoRight,
      logoSrcLeft: LogoLeft,
      InfoPc,
      InfoMob,
      AboutPc,
      AboutMob,
      menuSrc: menuWhite, // 菜单图表路径
      isUseMobileStyle: false, // 是否使用移动端样式
      moreMenu: true, // 是否显示的菜单图标
      closeSrc: closeWhite,
      timer: '',
      timerIndex: 0,
      timeTag: 0,
      interval: 140,
      imgList: {},
    };
  },
  mounted() {
    // 埋点
    mtaReport('weiheng_net.click', MTA_MENU_ID.home);

    this.handleCurrentChange(1);
    setTimeout(() => this.toTop(), 100);
    window.addEventListener('scroll', this.handleScroll);
    // todo 后续如果其他地方也需要添加移动端样式，该监听应在appjs里只初始化一次
    this.isUseMobileStyle = window.innerWidth < 769;
    window.onresize = () => (this.isUseMobileStyle = window.innerWidth < 769);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 关闭
    }
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    autoplay() {
      return !isMobile() || !isAndroid();
    },
  },
  methods: {
    handle(row) {
      const { path, nbr, menuId, event } = row;
      mtaReport(event, menuId);
      const newhref = this.$router.resolve({ path, query: nbr ? { type: nbr } : '' });
      window.open(newhref.href, '_blank');
    },
    toRecord() {
      window.open('https://beian.miit.gov.cn', '_blank');
    },
    // 信息公告分页
    handleCurrentChange(val) {
      // 每页3条
      this.sliceData = this.getNewArray(this.infoDataList.list, 3);
      // 提取对应数据
      this.infoDataList.data = this.sliceData.result[val - 1];
    },

    getNewArray(arr, size) {
      // size，要分割的长度,即每页的条数
      const arrNum = Math.ceil(arr.length / size, 10); // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
      let index = 0; // 定义初始索引
      let resIndex = 0; // 用来保存每次拆分的长度
      const result = [];
      while (index < arrNum) {
        result[index] = arr.slice(resIndex, size + resIndex);
        resIndex += size;
        index++;
      }
      return { size, result };
    },
    handleScroll() {
      const education = document.getElementById('education');
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (education.offsetTop <= scroll + 80) {
        this.hederStyle = 'hederStyle';
        this.logoSrcLeft = LogoLeftNew;
        this.logoSrcRight = LogoRightNew;
        this.menuSrc = menuBlack;
        this.closeSrc = closeBlack;
        this.activePage = 2;
      } else {
        this.hederStyle = '';
        this.logoSrcLeft = LogoLeft;
        this.logoSrcRight = LogoRight;
        this.menuSrc = menuWhite;
        this.closeSrc = closeWhite;
        this.activePage = 1;
      }
      const h = education.offsetTop + education.offsetHeight / 2;
      // 变量windowHeight是可视区的高度
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // 滚动条到底部的条件
      if (h < scroll || scroll + windowHeight + 1 >= scrollHeight) {
        this.activePage = 3;
      }
    },
    menuClick(title) {
      document.querySelector(title).scrollIntoView(true);
      this.moreMenu = true;
    },
    // 返回顶部
    toTop() {
      $('html,body').animate({ scrollTop: 0 }, 500);
    },
    clickMenu() {
      this.moreMenu = !this.moreMenu;
    },
    clickMenu1(index, row) {
      // 埋点
      mtaReport(row.event, row.menuId);

      setTimeout(() => {
        this.activePage = index;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr:hover > td:nth-child(1) {
  color: #2c57ec;
  cursor: pointer;
}
::v-deep .el-table td {
  color: #333333;
  border: none;
  cursor: pointer;
}
::v-deep .el-pager li.active,
::v-deep .el-pager li:hover {
  color: #2c57ec;
  cursor: pointer;
}

::v-deep .el-table::before {
  height: 0;
}
::v-deep .el-pagination button:hover {
  color: #2c57ec;
}
::v-deep .el-pagination,
::v-deep .el-pagination .el-pagination__total {
  color: #333;
}
.msg-list-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  .msg-item {
    display: flex;
    box-sizing: border-box;
    color: #000000;
    &:hover .table-title {
      color: #3462ed;
      cursor: pointer;
    }
    &:hover .table-day {
      color: #3462ed;
      cursor: pointer;
    }
  }
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.footer {
  width: 100%;
  // min-width: 1200px;
  // height: 86px;
  background: #363a44;
  // font-size: 18px;
  color: #fff;
  // line-height: 86px;
  .record-number {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.hederStyle {
  background: #ffffff;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.06);
  #navigator {
    .nav-li {
      .fp-tooltip a {
        color: #60656f;
      }
      .fp-tooltip-active a {
        color: #3462ed;
        border-bottom: solid 4px #2c57ec;
      }
    }
  }
}
.header {
  width: 100%;
  // min-width: 1200px;
  // height: 86px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 1000;
}
// 动画
.an1-enter-active,
.an1-leave-active {
  transition: all 1.7s;
}
.an1-enter, .an1-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(50%);
  opacity: 0;
}
.an1-leave, .an1-enter-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(0%);
  opacity: 1;
}

// 加大动画幅度animate-item-later2
.an1-enter, .an1-leave-to /* .fade-leave-active below version 2.1.8 */ {
  &.animate-item-later2 {
    transform: translateX(70%);
    opacity: 0;
  }
}
// 加大动画幅度animate-item-later3
.an1-enter, .an1-leave-to /* .fade-leave-active below version 2.1.8 */ {
  &.animate-item-later3 {
    transform: translateX(90%);
    opacity: 0;
  }
}
// 缩短动画时间animate-item-faster2
.an1-enter-active,
.an1-leave-active {
  &.animate-item-faster2 {
    transition: all 0.8s;
  }
}
</style>
